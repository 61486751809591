export const TRANSLATIONS_CS = {
    menu: {
        item1: 'O KEMPU',
        item2: 'PROGRAM',
        item3: 'ZÁZEMÍ',
        item4: 'PROČ NA TSC',
        item5: 'PŘIHLÁŠKA',
        item6: 'KONTAKT',
    },
    title_btn: "Jedinečný letní kemp na té nejvyšší úrovni!",
    about: {
        title: "O KEMPU",
        subtitle1: "Jedinečný letní kemp",
        subtitle2: "na té nejvyšší úrovni!",
        description1: "Florbalisti a florbalistky budou mít jedinečnou možnost trénovat na nejvyšší florbalové úrovni v ČR. Starší kategorie si projde kompletní sportovní přípravou pod vedením těch" +
            " nejkvalitnějších florbalových a kondičních trenérů. Nebude také chybět fyzioterapie" +
            " a sezení s profesionálním psychologem.",
        description2:
            "Mladší sportovci si naplno užijí všestranný pohybový rozvoj. Pohyb, vášeň a radost – to je trojice hodnot, na kterých si v Tatranu zakládáme. Náš zápal chceme přenést na všechny účastníky našeho jedinečného kempu.",
    },
    program: {
        title: "PROGRAM",
        subtitle1: "U náš si můžeš",
        subtitle2: "vybrat ze 2",
        subtitle3: "různých kategorií",
        list_item1: {
            title: "PERFORMANCE",
            subtitle: "PRO FLORBALISTY A FLORBALISTKY NAROZENÉ V LETECH 2009-2013",
            description: 'Chceš trénovat jako juniorští mistři světa? Chceš si vyzkoušet profesionální letní přípravu pod dohledem zkušených koučů? V tom případě je kategorie PERFORMANCE přesně pro tebe.',
            descriptionExpand: 'Starší hráče čeká kompletní letní příprava se vším, co k ní na profesionální úrovni patří. O tréninkový plán se postarají ti nejkvalitnější florbaloví a kondiční trenéři. Důraz ale klademe i na regeneraci a správné mentální nastavení, proto nebude chybět ani fyzioterapie a posezení se sportovním psychologem.',
        },
        list_item2: {
            title: "KIDS",
            subtitle: 'PRO MALÉ SPORTOVCE NAROZENÉ V LETECH 2013-2017',
            description: '\n' +
                'Rádi přivítáme jak florbalisty, tak i další sportovce! Pokud tě baví se hýbat a chceš zažít léto plné zábavy, s radostí tě na našem kempu přivítáme! Čeká tě spousta pohybových a sportovních her, základy gymnastiky a atletiky, a také samozřejmě florbal.',
            descriptionExpand: 'Starat se o Tebe budou ti nejlepší trenéři a to během všech aktivit po dobu celého kempu',
        }
    },
    service: {
        title1: "Profesionální",
        title2: "trenérský tým",
        title3: "je samozřejmostí",
        experiences: {
            title: "Díky znalostem, zkušenostem a spolupráci našich trenérů posouváme\n" +
                "                                    každého sportovce na jeho cestě kupředu.",
        },
        facilities: {
            title: "ZÁZEMÍ",
            subtitle1: "Florbalová hala.",
            subtitle2: "Regenerační zóna.",
            subtitle3: "Hřiště s umělým povrchem.",
            subtitle4: "Koupaliště a mnohem víc.",
        }
    },
    visit_us: {
        title: "Proč na TATRAN SUMMER CAMP?",
        item1: "Profesionální trenéři",
        item2: "Komplexní rozvoj hráčských dovedností",
        item3: "Klub s nejúspěšnější akademií v ČR",
        btn: "Přihláška na kemp",
    },
    why_tcs: {
        title: "PROČ TCS",
        subtitle1: "Sportovní kemp se",
        subtitle2: "zaměřením na florbal",
        about: {
            title1: "Špičková úroveň se základy Tatranské akademie",
            description: "Vášeň pro sport je to, co chceme u všech dětí a mládeže probudit.\n" +
                "                                                    Bezmála třicet\n" +
                "                                                    let pracujeme v Tatranu na výchově mladých nadšenců. Naši trenéři se\n" +
                "                                                    proto těší\n" +
                "                                                    až stejný zápal předají všem, kteří si s námi přijedou užít léto!",
            title2: "Zápalem a společnou energií probouzíme v dětech lásku k pohybu a\n" +
                "                                        sportu!",
        }
    },
    application: {
        title: "PŘIHLÁŠKA",
        subtitle1: "Tak co?",
        subtitle2: "Chceš zažít pořádný letní kemp?",
        subtitle3: "Stačí si jen vybrat ze 2 termínů",
        or: "Nebo",
        item1: {
            description: "Check-in: pondělí 1. 7. od 9:00 do 11:00 hodin (možnost přivézt účastníky již v neděli večer)",
            description_item1: "- Zahájení kempu: pondělí 1. 7. v 11:00",
            description_item2: "- Ukončení kempu: sobota 6. 7. ve 13:30",
            description_item3: "- Strava 6x denně + pitný režim",
            description_item4: "Cena a varianty ubytování:",
            description_item5: "- 6 290 Kč: vlastní ubytování",
            description_item6: "- 7 290 Kč: gymnastický sálek (spacáky)",
            description_item7: "- 8 290 Kč: ubytovna",
        },
        item2: {
            description: "Check-in: pondělí 22. 7. od 9:00 do 11:00 hodin (možnost přivézt účastníky již v neděli večer)",
            description_item1: "- Zahájení kempu: pondělí 22. 7. v 11:00",
            description_item2: "- Ukončení kempu: sobota 27. 7. ve 13:30",
            description_item3: "- Strava 6x denně + pitný režim",
            description_item4: "Cena a varianty ubytování:",
            description_item5: "- 6 290 Kč: vlastní ubytování",
            description_item6: "- 7 290 Kč: gymnastický sálek (spacáky)",
            description_item7: "- 8 290 Kč: ubytovna",
        }
    },
    partners: {
        title: "PARTNEŘI",
        subtitle1: "Partneři bez kterých",
        subtitle2: "by kemp nemohl proběhnout",
    },
    contact: {
        title: "Kontakt",
        need_help: "Potřebuješ poradit?",
        more_info: "Pro více informací o TATRAN SUMMER CAMP, nás klidně kontaktuj přímo na mail:",
        do_not_miss_anything: "Nechceš, aby ti něco uniklo? Tak to nás sleduj na sociálních sítích",
        about: "Více informací o Tatranu Střešovice:"
    }
};
