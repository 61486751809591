// React Required
import React, {Component} from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';

// Home layout
import PortfolioLanding from './home/PortfolioLanding';

import error404 from "./elements/error404";


import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component {
    state = {
        isLoading: true
    };

    constructor(props) {
        super(props);
        this.state = {isLoading: true}
    }

    componentDidMount() {
        console.log('App Loaded - Version 0.3.3');
        this.setState({isLoading: false})
    }

    render() {
        return (
            this.state.isLoading ? 'Loading' : <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={PortfolioLanding}/>
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
