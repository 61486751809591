import React, {Component} from "react";


class Application extends Component {
    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            date: this.props.date,
            description: this.props.description,
            descriptionItems: this.props.descriptionItems,
        }
    }

    onHandleVisibility = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        const {date, description, descriptionItems} = this.state;

        const {t} = this.props;

        return (
            <div data-aos="slide-up" data-aos-duration="400" data-aos-once="true"
                 data-aos-easing="ease-in-out" className="application-date-item">

                <h1>{date}</h1>
                {this.state.isOpen ?
                    <div className="application-description">
                        <span>{t(description)}</span><br/>
                        {descriptionItems.map((value, index) => (
                            <span>{t(value.text)}<br/></span>
                        ))}
                    </div> : null}

                <div className="switch">
                    {this.state.isOpen ?
                        <svg onClick={this.onHandleVisibility} width="58" height="58" viewBox="0 0 58 58">
                            <g id="Group_157" data-name="Group 157" transform="translate(-1359 -13623)">
                                <circle id="Ellipse_5" data-name="Ellipse 5" cx="29" cy="29" r="29"
                                        transform="translate(1359 13623)" fill="#6d0016"/>
                                <path id="Icon_open-plus" data-name="Icon open-plus" d="M0,7.708v5.139H20.555V7.708Z"
                                      transform="translate(1377.723 13641.723)" fill="#fef7ef"/>
                            </g>
                        </svg>
                        :
                        <svg onClick={this.onHandleVisibility} width="58" height="58" viewBox="0 0 58 58">
                            <circle id="Ellipse_4" data-name="Ellipse 4" cx="29" cy="29" r="29" fill="#6d0016"/>
                            <path id="Icon_open-plus" data-name="Icon open-plus"
                                  d="M7.708,0V7.708H0v5.139H7.708v7.708h5.139V12.847h7.708V7.708H12.847V0Z"
                                  transform="translate(18.723 18.723)" fill="#fef7ef"/>
                        </svg>
                    }
                </div>
            </div>

        )
    }
}

export default Application;
