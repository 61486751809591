import React, {Component} from "react";

class ServiceBackground extends Component {
    constructor(props) {
        super(props);
        this.state = {
            place: this.props.place,
            activity: this.props.activity,
            image: this.props.image,
        }
    }

    render() {
        const {place, activity, image} = this.state;

        return (
            <div data-aos="fade-in" data-aos-duration="400" data-aos-once="true"
                 data-aos-easing="ease-in-out" className="background-segment">
                {image ? <img className="background-image" src={`/assets/images/services/${image}`}
                              loading="lazy" alt={activity}/> : null}
                <p className="background-place">{place}</p>
                <p className="background-activity">{activity}</p>
            </div>

        )
    }
}

export default ServiceBackground;
