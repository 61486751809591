export const TRANSLATIONS_EN = {
    menu: {
        item1: 'ABOUT THE CAMP',
        item2: 'PROGRAM',
        item3: 'FACILITIES',
        item4: 'WHY TSC?',
        item5: 'APPLICATION FORM',
        item6: 'CONTACT',
    },
    title_btn: "A unique high-level summer camp!",
    about: {
        title: "ABOUT THE CAMP",
        subtitle1: "A unique high-level summer camp!",
        subtitle2: "",
        description1: "Players from all over the world will have a unique opportunity to train at the highest " +
            "floorball level in the Czech Republic. The older category will go through a holistic sports preparation " +
            "under the guidance of the best floorball and fitness coaches. Physiotherapy and workshops led by professional sports psychologist will also be part of the camp. ",
        description2: "The younger athletes will enjoy X days of fun full of all-round physical development. " +
            "Movement, passion and joy - that's the trio of values we pride ourselves on at Tatran. We want to transfer our passion to all young players participating in our unique camp.",
    },
    program: {
        title: "PROGRAM",
        subtitle1: "You can choose",
        subtitle2: "from 2",
        subtitle3: "different categories",
        list_item1: {
            title: "PERFORMANCE",
            subtitle: "FOR MALE AND FEMALE FLOORBALL PLAYERS BORN IN 2009-2013",
            description: 'Do you want to train like junior world champions? Do you want to try professional training under the supervision of experienced coaches? If your answer is yes, the PERFORMANCE category is for you.',
            descriptionExpand: 'Senior players will have a holistic training with everything that goes with it at a professional level. The training plan will be taken care of by the best floorball and fitness coaches. But we are also aware of the huge impact of the professional sport on a players’ health and mind and that is why physiotherapy and workshops led by professional sports psychologist will also be part of the camp!',
        },
        list_item2: {
            title: "KIDS",
            subtitle: 'FOR YOUNG ATHLETES BORN BETWEEN 2013-2017',
            description: 'We will be happy to welcome all young floorball players but also other athletes without previous floorball experience. If you love to move and if you want to experience X days full of fun with some new friends, this camp is for you! A lot of fun activities, sports games, basics of gymnastics and athletics and floorball, of course, are waiting for you!',
            descriptionExpand: 'A team of professional coaches will take care of you all the time during all activities at the Tatran Summer Camp.',
        }
    },
    service: {
        title1: "Professional",
        title2: "coaching team",
        title3: "is a matter of course",
        experiences: {
            title: "Thanks to the knowledge, experience and cooperation of our coaches, we move each athlete forward on their journey.",
        },
        facilities: {
            title: "FACILITIES",
            subtitle1: "Floorball hall.",
            subtitle2: "Regeneration zone.",
            subtitle3: "Sports ground with artificial surface.",
            subtitle4: "Swimming pool and much more.",
        }
    },
    visit_us: {
        title: "Why attend TATRAN SUMMER CAMP?",
        item1: "Professional coaches",
        item2: "Progressive player skills development",
        item3: "Club with the most successful academy in the Czech Republic",
        btn: "Application form",
    },
    why_tcs: {
        title: "WHY TCS",
        subtitle1: "Sports camp with ",
        subtitle2: "focusing on floorball",
        about: {
            title1: "Top-level summer camp based on the Tatran’s academy values",
            description: "Passion for the sport is the thing we want to awaken in all young players. For almost thirty years we have been working at Tatran to develop young sports enthusiasts. " +
                "Our coaches are looking forward to passing on the same passion to everyone who comes to enjoy the summer with us!",
            title2: "We awaken the love for movement and sports in children by our enthusiasm and Tatran’s energy!",
        }
    },
    application: {
        title: "APPLICATION",
        subtitle1: "So?",
        subtitle2: "Do you want to experience Tatran Summer Camp?",
        subtitle3: "It’s easy, you can just choose between these two dates, fill in the form, and come and have fun!",
        or: "Or",
        item1: {
            description: "Check-in: Monday 1 July from 9:00 to 11:00 a.m. (possibility to arrive already on Sunday evening)",
            description_item1: "- Start of the camp: Monday 1 July at 11:00",
            description_item2: "- End of the camp: Saturday 6 July at 13:30",
            description_item3: "- Meals 6 times a day + drinking regime",
            description_item4: "Price and accommodation options:",
            description_item5: "- 6 290 CZK: your own accommodation",
            description_item6: "- 7 290 CZK: gymnasium (sleeping bags)",
            description_item7: "-8 290 CZK: hostel",
        },
        item2: {
            description: "Check-in: Monday 22 July from 9:00 to 11:00 (possibility to arrive already on Sunday evening)",
            description_item1: "- Start of the camp: Monday 22th July at 11:00",
            description_item2: "- Camp closure: Saturday 27 July at 13:30",
            description_item3: "- Meals 6 times a day + drinking regime",
            description_item4: "Price and accommodation options:",
            description_item5: "- 6 290 CZK: your own accommodation",
            description_item6: "- 7 290 CZK: gymnasium (sleeping bags)",
            description_item7: "-8 290 CZK: hostel",
        }
    },
    partners: {
        title: "PARTNERS",
        subtitle1: "Partners without whom",
        subtitle2: "the camp would not exist",
    },
    contact: {
        title: "Contact",
        need_help: "Is there something you are not sure about?",
        more_info: "For more information regarding TATRAN SUMMER CAMP, please contact us directly by e-mail:",
        do_not_miss_anything: "You don't want to miss anything? Then, follow us on social media",
        about: "More information about Tatran Stresovice:"
    }
};
